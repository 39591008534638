import axios from 'axios'

import pagina from "@/class/pagina";

export class Aluno {
  static async obtemTodos (n) {
    const routePg = pagina.routePg;
    let ano = sessionStorage.getItem("anoSelecionado");
    return axios.get(`/alunos/ano/${ano}?page=${n}${routePg}`);
  }
  static async obtemTodos2 () {
    let ano = sessionStorage.getItem("anoSelecionado");
    return axios.get('/alunos/v2/'+ano)
  }
  static async mudarSituacaoAluno (id) {
    return axios.put('alunos/'+id+'/situacao')
  }
  static async obtemUm (id) {
    return axios.get('/alunos/'+id)
  }
  static async cadastrar (payload) {
    const config={
      timeout: 2000
    }
    return axios.post('/alunos', payload,config);
  }
  static async cadastrarArquivo (aluno_id, arquivo) {
    const config = {
      header : {
       'Content-Type' : 'multipart/form-data'
     }
    }
    return axios.post('alunos/arquivo/'+aluno_id, arquivo, config);
  }
  static async buscarArquivoAluno (aluno_id, local, data) {
   // return axios.post('alunos/arquivo/4/local/justificarFalta?data=2022-06-24 );
    if (data != undefined)
      {return axios.post('alunos/arquivo/'+aluno_id+'/local/'+local+'?data='+data);}
    else
      {return axios.post('alunos/arquivo/'+aluno_id+'/local/'+local);}
  }
  static async deletarArquivo (payload) {
    return axios.delete('alunos/arquivo/'+payload.aluno_id+'/'+payload.id);
  }
  static async alterar (payload) {
    return axios.put('/alunos/'+payload.id, payload)
  }
  static async alterarLogin (payload) {
    return axios.put('/alunos/'+payload.id+'/login', payload)
  }
  static async deletarLogin (payload) {
    return axios.delete('/alunos/'+payload.id+'/login', payload)
  }
  static async remover (id) {
    return axios.delete('/alunos/'+id)
  }
  static async addAlunoTurma (payload) {
    return axios.post('/aluno/turma', payload)
  }
  static async addAlunoTransferidoDeEscola (payload) {
    return axios.post('evento/aluno/transferido', payload)
  }
  static async addAlunosTurma (alunos) {
    return axios.post('/alunos/turma', alunos)
  }
  static async TransferenciaInternaAluno (payload) {
    return axios.post('/evento/turma/interna/transferecia', payload);
  }
  static async addAlunosTurma (alunos) {
    return axios.post('/alunos/turma',  {
      alunos: alunos,
    });
  }
  static async editarAlunoTurma (payload) {
    return axios.put('/aluno/turma/'+payload.id, payload)
  }
  static async deletarAlunoTurma(id) {
    return axios.delete('/aluno/turma/'+id)
  }
  static async deletarAlunoTurmaV2(payload) {
    return axios.delete('aluno/turma/delete/v2',{
      params: {
        ano: payload.ano,
        aluno_id: payload.aluno_id,
        turma_id: payload.turma_id,
      }
    })
  }

  static async history (aluno_id,ano) {
    return axios.get('/historico/'+aluno_id+'/'+ano)
  }

  static async  RemoverAlunoTurma(payload) {
    return axios.post('/evento/turma/remover', payload);
  }
  static async historicoAluno (payload) {
    return axios.post('/aluno/history/series/all', payload)
  }
  //quando a presença e nota do aluno for ativada deve adicionar historico do aluno
  static async adicionarItemHistoricoAluno (payload) {
    return axios.post('/aluno/history/series', payload)
  }

   //pesquisa por item completo
  static async pesqAlunoCompleto (payload) {
    return axios.get('/alunos/pesquisa/'+payload+'/completa')
  }

  static async deletarAluno(id){
    return axios.delete('/alunos/'+id)
  }


  //arquivos cadastro xml

  static async salvarXmlAlunos (payload) {
    return axios.post('/alunos_array',  {
        array: payload,
    });
  }

}
